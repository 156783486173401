import React, {useState} from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import Lottie from 'react-lottie';
// imagenes
import MenuImg from "../../assets/images/menu/Menu.svg";
import HerdezImg from "../../assets/images/menu/Herdez.svg";
import MenuWitheImg from "../../assets/images/menu/MenuWhite.png";
import HerdezWhitheImg from "../../assets/images/menu/HerdezWhite.svg";
import Logo from "../../assets/images/logocosmos.png";
import CloseImg from "../../assets/images/menu/CloseSvg.svg";
import DownArrow from "../../assets/images/menu/flechaabajo.svg";
import CosmoBalnco from "../../assets/images/CosmosWhite.png";
import LogoAnimation from '../../assets/amimation/Logo.json'
import LogoAnimationBlanco from '../../assets/amimation/LogoBlanco.json'
import { useNavigate } from "react-router-dom";

import {
  HeaderContainer,
  HeaderMenu,
  dataRoute,
  CustomSelect,
  SelectButton,
  OptionList,
  OptionItem,
  
} from "./headerStyle";

const HeaderComponent = ({
  isBackground,
  windowSize,
  openModal,
  MenuDesk,
  CloseModalOpenDesk,
  dataRoute,
  HanlderSelectRouterMenu,
  HanlderSelectedFooter
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: isBackground === true ? LogoAnimationBlanco  : LogoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const navigate = useNavigate();
  
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const HanlderOpenDropDownSelected = () => {
        if(isSelectOpen === true){
          setIsSelectOpen(false)
        } else {
          setIsSelectOpen(true)
        }
  }

  const HanlderGoHome = () => {
    navigate("/")
  }
  const variants = {
    open: { width: "100%" },
    closed: { width: "0%" },
  };

  const HanlderNavigatreMenu = (data) =>{
    if(data === "objetivo" || data === "tipos-de-proyectos"){
      HanlderSelectRouterMenu(data)
      navigate(`/${data}`, { replace: true })
    } else {
      switch (data) {
        case "DEFINICIÓN ESTRATÉGICA":
          navigate("/definicion-estrategica")
        CloseModalOpenDesk()
          break;
        case "IDEACIÓN Y PLAN DE NEGOCIOS":
          navigate("/ideacion", { replace: true })
        CloseModalOpenDesk()
          break;
        case "DESARROLLO":
          navigate("/desarrollo", { replace: true })
        CloseModalOpenDesk()
          break;
        case "FABRICACIÓN":
          navigate("/fabricacion", { replace: true })
        CloseModalOpenDesk()
          break;
        case "SEGUIMIENTO":
          navigate("/seguimiento", { replace: true })
        CloseModalOpenDesk()
          break;
        default:
          break;
      }
      HanlderSelectedFooter(data)
    }

    CloseModalOpenDesk()
  }
 
  const options = ["Opción 1", "Opción 2", "Opción 3", "Opción 4"];
  return (
    <AnimatePresence>
      {MenuDesk ? (
        <HeaderMenu
          key="menu"
          isOpen={MenuDesk}
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
          isBackground={isBackground}
        >
          <div onClick={CloseModalOpenDesk} className="ContainerCloseImg">
            <img src={CloseImg} alt="" srcset="" />
          </div>
          <div  
          onClick={() => HanlderNavigatreMenu("objetivo")}
          className="ContainerItemMenuDesk">
            <p>OBJETIVO DEL PROCESO</p>
          </div>
          <div 
          onClick={() => HanlderNavigatreMenu("tipos-de-proyectos")}
          className="ContainerItemMenuDesk">
            <p>TIPOS DE PROYECTO</p>
          </div>
          <div className="ContainerItemMenuDeskDown">
            <CustomSelect
               isBackground={isBackground}
            >
              <SelectButton
                 isBackground={isBackground}
                onClick={() => HanlderOpenDropDownSelected()}
              >
                MACROPROCESO
                <img src={DownArrow} alt="Flecha hacia abajo" />
              </SelectButton>
              <OptionList
                 isBackground={isBackground}
                style={isSelectOpen == true ? {display: 'block'} : {display: 'none'}}
              >
                <ul className="ContainerUl">
                {dataRoute.map((option, index) => (
                  <OptionItem 
                  isBackground={isBackground}
                  onClick={() => HanlderNavigatreMenu(option.nombre)}
                  key={index}>{option.nombre}</OptionItem>
                ))}
                </ul>
               
              </OptionList>
            </CustomSelect>
          </div>
          <div className="ContainerHederLogoMenu">
            <Lottie 
            width={130}
            options={defaultOptions}/>
            {/* <img width={130} src={Logo} alt="" srcset="" /> */}
          </div>
        </HeaderMenu>
      ) : (
        <HeaderContainer 
        isBackground={isBackground}
        style={MenuDesk ? { opacity: 0 } : { opacity: 1 }}>
          <div className="containerLeftMenu" >
            <img 
            onClick={() => openModal()}
            style={{cursor: 'pointer'}}
            src={isBackground ? MenuWitheImg : MenuImg} alt="" srcSet="" />
            <div
            onClick={() =>HanlderGoHome()}
              style={
                windowSize.width > 838
                  ? { display: "block",cursor: 'pointer' }
                  : { display: "none" }
              }
            >
              <img className="ContainerHerdezImgHeader"
                src={isBackground ? HerdezWhitheImg : HerdezImg}
                alt=""
                srcSet=""
              />
            </div>
          </div>
          <div
            className="ContainerLogoHeader"
            onClick={() =>HanlderGoHome()}
            style={
              
              windowSize.width < 838
                ? { display: "block",cursor: 'pointer' }
                : { display: "none" }
            }
          >
              <Lottie 
              width={150}
              options={defaultOptions}/>
            {/* <img 
            src={isBackground ? CosmoBalnco  :Logo} alt="" srcSet="" /> */}
          </div>
          <div>
            {
              windowSize.width < 838 ?
              <img
              width={windowSize.width < 838 ? 30 : 120}
              src={isBackground ? HerdezWhitheImg : HerdezImg}
              alt=""
              srcSet=""
            /> 
            :
            <Lottie 
            width={windowSize.width < 838 ? 93 :130}
            options={defaultOptions}/>
            }
            {/* <img
              width={windowSize.width < 838 ? 30 : 120}
              src={windowSize.width < 838 ? HerdezImg : Logo}
              alt=""
              srcSet=""
            /> */}
          </div>
        </HeaderContainer>
      )}
    </AnimatePresence>
  );
};

export default HeaderComponent;
