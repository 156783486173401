import React, { useState } from "react";
import { ContainerTopBarDesk, ContainerTopBarMovil } from "./TopBarStyles";
// imagenes
import ArrowDown from "../../assets/images/menu/flechaabajo.svg";
import DefinicionIcon from "../../assets/images/menu/Topmenu/DefinicionestrategicaMovil.svg";
import FabricacionIcon from "../../assets/images/menu/Topmenu/fabricacionMovil.svg";
import IdeacicionIcon from "../../assets/images/menu/Topmenu/IdeacionyplandenegocioMovil.svg";
import DesarrolloIcon from "../../assets/images/menu/Topmenu/desarrolloMovil.svg";
import SeguimientoIcon from "../../assets/images/menu/Topmenu/SeguimientoMovil.svg";
const TopBar = ({
  ListData,
  windowSize,
  HanlderSelectRouter,
  ListDataMovil,
  RutaSeleccionada,
}) => {
  // onClick={() => setIniciativasList(sortedIniciativas)}
  const [ShowFloatMenuHome, setShowFloatMenuHome] = useState(false);

  const HanlderShowFloatMenu = () => {
    if (ShowFloatMenuHome === true) {
      setShowFloatMenuHome(false);
    } else {
      setShowFloatMenuHome(true);
    }
  };
 


  return windowSize.width <= 837 ? (
    <ContainerTopBarMovil>
      <div
        onClick={() => HanlderShowFloatMenu()}
        className="ContainerArrowDownItem"
      >
        <div
          className="ItemMenuTopBarMovil"
          style={{ marginBottom: -10, paddingBottom: 0 }}
        >
          <p style={{ color: "#3F348B" }}>{RutaSeleccionada}</p>
        </div>
        <div>
          <img src={ArrowDown} alt="ArrowDown" />
        </div>
      </div>
      <div
        style={
          ShowFloatMenuHome === true
            ? { display: "block" }
            : { display: "none" }
        }
        className="FloatMenuMovilHome"
      >
        <div className="ItemMenuTopBarMovil">
          <p
            onClick={() => HanlderSelectRouter("TODOS",'')}
            style={{
              color: "#3F348B",
              paddingBottom: "0px !important",
              marginBottom: 5,
            }}
          >
            TODOS
          </p>
        </div>
        <div className="ItemMenuTopBarMovil">
          {ListDataMovil?.map((iniciativaMovil) => {
            return (
              <p
                onClick={() => HanlderSelectRouter(iniciativaMovil?.nombre,iniciativaMovil?.color)}
                style={{ color: iniciativaMovil?.color }}
              >
                {iniciativaMovil?.nombre}
              </p>
            );
          })}
        </div>
          <div className="ContainerImgMenumovil">
          <div onClick={() => HanlderSelectRouter("DEFINICIÓN ESTRATÉGICA",'')}>
          <img src={DefinicionIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("IDEACIÓN Y PLAN DE NEGOCIO",'')}>
          <img src={IdeacicionIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("DESARROLLO",'')}>
          <img src={DesarrolloIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("FABRICACIÓN",'')}>
          <img src={FabricacionIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("SEGUIMIENTO",'')}>
          <img src={SeguimientoIcon} alt="IConMenu" srcset="" />
        </div>
          </div>
      </div>
    </ContainerTopBarMovil>
  ) : (
    <ContainerTopBarDesk>
      <p onClick={() => HanlderSelectRouter("TODOS",'')} className="TopBarTodos">
        TODOS
      </p>
      {ListData.map((iniciativa) => (
        <p
          onClick={() => HanlderSelectRouter(iniciativa?.nombre,iniciativa?.color)}
          className="TopBarItem"
          style={RutaSeleccionada === iniciativa?.nombre  ? { color: iniciativa?.color,opacity: 1 } : { color: iniciativa?.color, opacity: 0.3 }}
          key={iniciativa?.id}
        >
          {iniciativa?.nombre}
        </p>
      ))}
    </ContainerTopBarDesk>
  );
};

export default TopBar;
