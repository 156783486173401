import React, { useState, useEffect } from "react";
import ArrowDown from "../../assets/images/menu/flechaabajo.svg";
import Slider from "react-slick";

import {
  ContainerTopBarDesk,
  ContainerTopBarMovil,
} from "../home/TopBarStyles";
import ModalImage from "../../utils/components/modals/ModalImage";
import useModals from "../../utils/components/modals/useModals";
const ProyectoComponent = ({
  DataProyectos,
  windowSize,
  apiImage,
  buscarProyecto,
  HanlderChangueProyecto,
  DataProyecto,
  HanlderSelectRouterMenu,
  OpenModalMarcoPrime,
}) => {
  const [ShowMenuProyecto, setShowMenuProyecto] = useState(false);
  const [ProyectoSeleccionado, setProyectoSeleccionado] = useState("ARTES");
  const [ColorSeleccionado, setColorSeleccionado] = useState("#FFC200");
  const { ModalImages, OpenModalImages, CloseModalImages } = useModals();

  useEffect(() => {
    if (DataProyecto === undefined) {
      HanlderSelectRouterMenu();
    }
  }, [DataProyecto]);

  const etiquetasString = DataProyecto?.etiquetas.join(" | ");

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const HanlderCambioMenu = (data) => {
    switch (data.titulo) {
      case "ARTES":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#FFC200");
        setShowMenuProyecto(false);
        break;
      case "COMBO":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#E2006C");
        setShowMenuProyecto(false);
        break;

      case "EXHIBICIÓN":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#8F00AD");
        setShowMenuProyecto(false);
        break;

      case "RÉPLICA":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#21CEA6");
        setShowMenuProyecto(false);
        break;

      case "INICIATIVA DISRUPTIVA":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#0080FF");
        setShowMenuProyecto(false);
        break;

      case "INICIATIVA INCREMENTAL":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#7AD800");
        setShowMenuProyecto(false);
        break;

      case "IMPORTACIONES":
        setProyectoSeleccionado(data.titulo);
        setColorSeleccionado("#00BF1B");
        setShowMenuProyecto(false);
        break;
      default:
        break;
    }
  };

  const HanlderShowMenu = () => {
    if (ShowMenuProyecto === true) {
      setShowMenuProyecto(false);
    } else {
      setShowMenuProyecto(true);
    }
  };



  useEffect(() => {
    const objetoSeleccionado = buscarProyecto(ProyectoSeleccionado);
    HanlderChangueProyecto(objetoSeleccionado);
  }, [ProyectoSeleccionado]);

  return (
    <div 
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    }}
    className="ContainerObjetivo">
      {windowSize?.width <= 837 ? (
        <div>
          <ContainerTopBarMovil>
            <div
              onClick={() => HanlderShowMenu()}
              className="ContainerArrowDownItem"
            >
              <div
                className="ItemMenuTopBarMovil"
                style={{ marginBottom: -10, paddingBottom: 0 }}
              >
                <p style={{ color: ColorSeleccionado }}>
                  {ProyectoSeleccionado}
                </p>
              </div>
              <div>
                <img src={ArrowDown} alt="ArrowDown" />
              </div>
            </div>

            <div
              style={
                ShowMenuProyecto === true
                  ? { display: "block" }
                  : { display: "none" }
              }
              className="FloatMenuMovilHome"
            >
              <div className="ItemMenuTopBarMovil">
                {DataProyectos?.map((r) => {
                  return (
                    <div
                      onClick={() => HanlderCambioMenu(r)}
                      style={{ cursor: "pointer" }}
                      className="ContainerIconMenuProyectos"
                    >
                      <img
                        width={20}
                        height={20}
                        src={`${apiImage}${r?.icono}`}
                        alt=""
                        srcset=""
                      />
                      <p
                        style={{
                          color:
                            r?.titulo === "ARTES"
                              ? "#FFC200"
                              : r?.titulo === "COMBO"
                              ? "#E2006C"
                              : r?.titulo === "EXHIBICIÓN"
                              ? "#8F00AD"
                              : r?.titulo === "RÉPLICA"
                              ? "#21CEA6"
                              : r?.titulo === "INICIATIVA DISRUPTIVA"
                              ? "#0080FF"
                              : r?.titulo === "INICIATIVA INCREMENTAL"
                              ? "#7AD800"
                              : r?.titulo === "IMPORTACIONES"
                              ? "#00BF1B"
                              : "",
                        }}
                      >
                        {r?.titulo}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </ContainerTopBarMovil>
          <div className="ContainerTitleproyect">
            <h2>Tipos de proyecto</h2>
            <div className="LineProyect"></div>
          </div>
        </div>
      ) : (
        <div>
          <div className="ContainerTitleproyect">
            <h2>Tipos de proyecto</h2>
            <div className="LineProyect"></div>
          </div>
          <ContainerTopBarDesk>
            {DataProyectos?.map((r) => {
              return (
                <div
                  onClick={() => HanlderCambioMenu(r)}
                  style={
                    ProyectoSeleccionado === r?.titulo
                      ? { opacity: 1, cursor: "pointer" }
                      : { opacity: 0.5, cursor: "pointer" }
                  }
                  className="ContainerIconMenuProyectos"
                >
                  <img
                    width={20}
                    height={20}
                    src={`${apiImage}${r?.icono}`}
                    alt=""
                    srcset=""
                  />
                  <p
                    style={{
                      color:
                        r?.titulo === "ARTES"
                          ? "#FFC200"
                          : r?.titulo === "COMBO"
                          ? "#E2006C"
                          : r?.titulo === "EXHIBICIÓN"
                          ? "#8F00AD"
                          : r?.titulo === "RÉPLICA"
                          ? "#21CEA6"
                          : r?.titulo === "INICIATIVA DISRUPTIVA"
                          ? "#0080FF"
                          : r?.titulo === "INICIATIVA INCREMENTAL"
                          ? "#7AD800"
                          : r?.titulo === "IMPORTACIONES"
                          ? "#00BF1B"
                          : "",
                    }}
                  >
                    {r?.titulo}
                  </p>
                </div>
              );
            })}
          </ContainerTopBarDesk>
        </div>
      )}

      <div 
      
      className="ContainerFlexProyectos"
      >
        <div className="ContainerProyectosLeft">
          <div
            style={
              ProyectoSeleccionado === "ARTES"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "40%",
                  }
                : ProyectoSeleccionado === "COMBO"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "40%",
                  }
                : ProyectoSeleccionado === "EXHIBICIÓN"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "50%",
                  }
                : ProyectoSeleccionado === "INICIATIVA DISRUPTIVA"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "50%",
                  }
                : ProyectoSeleccionado === "INICIATIVA INCREMENTAL"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "60%",
                  }
                : ProyectoSeleccionado === "IMPORTACIONES"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "40%",
                  }
                : { background: "" }
            }
            className="EtiquetaProyectos"
          >
            <p>{etiquetasString}</p>
          </div>
          <div 
          style={etiquetasString === "" ? {marginTop: -20} : {background: 'transparent'}}
          className="InfoTextProyectos">
            <p>{DataProyecto?.descripcion}</p>
          </div>

          <div>
            <div
              style={
                windowSize?.width <= 837
                  ? { display: "none" }
                  : { display: "block" }
              }
              onClick={() => OpenModalImages()}
              className="ButtonFase"
            >
              <p>VER PROCESO</p>
            </div>
          </div>
        </div>
        <div className="ContainerFlexProyectosRiht">
          <div className="ContainerNombreExito">
            <p>Casos de éxito</p>
          </div>

          <div className="ContainerProyectosExito" style={{
            borderRadius: 10
          }}>
            <Slider
              {...settings}
              style={{
                width: 400, // Ancho fijo
                height: windowSize?.width <= 837 ? 226  : 282,
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: '10px !important'
                 // Asegura que el carrusel no sea más ancho que su contenedor
              }}
            >
              {DataProyecto?.casosExito.map((ce, index) => (
                <div key={index} className="SliderItem">
                  <img
                    style={{
                      width: "100%",
                      height: "auto", // Permite que la altura se ajuste automáticamente
                      objectFit: "contain",
                      borderRadius: 10,
                    }}
                    src={`${apiImage}${ce?.imagen}`}
                    alt={`Casos de éxito ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div
            style={
              windowSize?.width <= 837
                ? { display: "block", marginBottom: 30, marginTop: 30 }
                : { display: "none" }
            }
            onClick={() => OpenModalImages()}
            className="ButtonFase"
          >
            <p>VER PROCESO</p>
          </div>
        </div>
      </div>
      <ModalImage
        modal={ModalImages}
        closemodal={CloseModalImages}
        Tipo="Manual"
        windowSize={windowSize}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            width={windowSize?.width <= 837 ? "100%" : "60%"}
            src={
              windowSize?.width <= 837
                ? `${apiImage}${DataProyecto?.proceso_mobile}`
                : `${apiImage}${DataProyecto?.proceso}`
            }
            alt=""
            srcset=""
          />
        </div>
      </ModalImage>
    </div>
  );
};

export default ProyectoComponent;
