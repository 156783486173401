import styled from "styled-components"
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const ContainerTopBarDesk = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom:10px;
    padding-top: 10px;
    z-index: 1;
    .TopBarTodos{
        color: rgb(63 52 139 / 69%);
        font-weight: bold;
        font-family: 'Montserrat-Medium', sans-serif;
        cursor: pointer;
    }
    .TopBarItem{
        font-weight: bold;
        font-family: 'Montserrat-Medium', sans-serif;
        cursor: pointer;
    }
    .ContainerIconMenuProyectos{
      display: flex;
      justify-content: center;
    }
    .ContainerIconMenuProyectos img{
      margin-right: 10px;
    }
    .ContainerIconMenuProyectos p{
      display: flex;
      font-weight: bold;
        font-family: 'Montserrat-Medium', sans-serif;
      justify-content: center;
    }
  // Mobil Frist
  @media screen and (min-width: 200px){
    width: 100%;
    font-size: 12px;
  }
  @media screen and (min-width: 720px){
    width: 98%;
  }
  @media screen and (min-width: 900px){
    
  }
  @media screen and (min-width: 1200px){
    font-size: 17px;
    width: 90%;
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
 
    // DESK
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}


`
export const ContainerTopBarMovil = styled.div`

    width: 90%;
    border-bottom: 1px solid #EFEFEF;
    margin: auto;
    position: relative;
    margin-top: 20px;
  // Mobil Frist
  @media screen and (min-width: 200px){
    .FloatMenuMovilHome{
      position: absolute;
      background: #fff;
      border-radius: 10px;
      z-index: 2;
      width: 100%;
      top:20px;
      left: 0px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      // sss 
    }
    .ContainerArrowDownItem{
      display: flex;
      justify-content: space-between;
    }
    .ItemMenuTopBarMovil{
      flex: 1;
      padding-bottom: 10px;
    }
    .ItemMenuTopBarMovil p {
      text-align: center;
      font-weight: bold;
      font-family: 'Montserrat-Medium', sans-serif;
      font-size: 12px;
      margin-bottom: 15px;
    }
    .ContainerIconMenuProyectos{
      display: flex;
      justify-content: center;
    }
    .ContainerIconMenuProyectos img{
      margin-right: 10px;
    }
    .ContainerImgMenumovil{
      display: flex;
      flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
 
    // DESK
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}
`